/**
 * Definition of CSS3 variables to be used throughout this projects stylesheets.
 */

:root {
  --clf-blue: #1345F0;
  --clf-green: #00F28A;
  --clf-white: #FFFFFF;
  --clf-black: #0A0A0A;
  --clf-orange: #FFA500FF;
  --dhl-yellow: #FFCC00;
  --dhl-red: #d40511;
  --dhl-red-dark: #bc040b;
  --dhl-red-light: #fcd7d9;
  --white: #fff;
  --black: #000;
  --link-hover: #0072a3;
  --link-hover-overlaypannel: #e8e8e8;

  --navbar-height: 4rem;


}
