/**
 * Global Layout Styles
 */

html {
  font-size: 14px; // Used by PrimeNG to change the size of everything that is in REM. 1 REM = font-size in px
}

// Application should use the full screen and any overflow (scrolling) is handled in child components
html,
body,
.app-container {
  height: 100%;
  width: 100%;
}

.pages {
  overflow-x: hidden;
}

body {
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.submit-button .p-button {
  border: 1px solid transparent;
  background-color: var(--dhl-red);
}

.submit-button .p-button:enabled:hover, .submit-button .p-button:enabled:focus {
  background-color: var(--dhl-red-dark)
}

.cancel-button .p-button, .cancel-button .p-button:enabled:focus {
  color: var(--dhl-red-dark);
  background-color: var(--clf-white);
  border: 1px solid var(--dhl-red);
}

.cancel-button .p-button:enabled:hover {
  color: var(--dhl-red-dark);
  background-color: var(--dhl-red-light);
}

.button-open-overlay-panel {
  text-align: center;
}

.table-button-overlay-panel {
  width: 2rem;
}

.skeleton-input {
  width: 200px !important;
  height: 47px !important;
}

.skeleton-label-height {
  height: 1.3rem !important;
}

.skeleton-button-height {
  height: 47px !important;
}

.table-list {
  padding-left: 0;
  display: inline;
}

.table-list li {
  display: inline;
}

.table-list li:not(:last-child):after {
  content: ", ";
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  width: 100px;
  height: 100px;
  border: 6px solid #f3f3f3;
  border-top-color: #ffcc00;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  z-index: 9999;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
