/**
 * Overrides of the imported PrimeNG Theme.
 */

:root {
  --primary-color: var(--dhl-yellow);
  --primary-color-text: var(--black);

  overflow-x: hidden;
}

.p-button {
  background-color: var(--primary-color);
}

a:hover {
  color: var(--link-hover);
  text-decoration: underline;
}

a {
  cursor: pointer;
  text-decoration: none;
}

a:visited {
  color: transparent;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0.6rem;
}

.p-overlaypanel .p-overlaypanel-content a {
  padding: 0.3rem;
}

.p-overlaypanel .p-overlaypanel-content a:hover {
  background-color: var(--link-hover-overlaypannel);
  color: black;
  text-decoration: none;
}

.p-dialog {
  width: 30vw;
}

.p-inputtext, .p-dropdown {
  width: 200px;
}

.p-multiselect {
  min-width: 200px !important;
  max-width: 100% !important;
}
